import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../../styles'
import awsconfig from '../../aws-exports'
import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'

Amplify.configure({
    Auth: {
        identityPoolId: awsconfig.aws_cognito_identity_pool_id,
        region: awsconfig.aws_cognito_region,
        userPoolId: awsconfig.aws_user_pools_id,
        mandatorySignIn: false,
        userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    },
    Storage: {
        bucket: awsconfig.aws_user_files_s3_bucket,
        region: awsconfig.aws_user_files_s3_bucket_region,
        identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    },
})

const Wrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
`

const Label = styled.label`
    display: block;
    margin-bottom: 10px;
`

const ControlWrap = styled.div`
    display: inline-block;
    position: relative;

    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        cursor: pointer;
    }
`

const IconWrapper = styled.label`
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 1em 2em;
    background-color: ${colors.dark};
    color: white;
    text-transform: uppercase;
    transition: all 0.45s;
    font-family: ${fonts.special};

    &:hover {
        background-color: ${colors.darkGrey};
        color: black;
    }

    svg {
        display: inline-block;
        margin-right: 1em;
        fill: currentColor;
    }
`
async function signIn() {
    try {
        const user = await Auth.signIn(
            process.env.GATSBY_AUTH_EMAIL,
            process.env.GATSBY_AUTH_PASSWORD
        )
        console.log(user)
    } catch (error) {
        console.log(error)
    }
}

const FileInput = ({ id, label, fileTypes, children, setFileLink }) => {
    const [status, setStatus] = useState(null)
    const timestamp = Date.now()
    const s3UrlBase = `https://solarenergypartners984417437254437788805049335b173728-staging.s3.amazonaws.com/public/`

    const handleFileInput = async (e) => {
        const file = e.target.files[0]
        const fileName = `${timestamp}-${file.name}`
        await Storage.put(fileName, file).then((res) => {
            console.log(res)
            if (res.key) {
                const fileLink = `${s3UrlBase}${res.key}`
                setFileLink(fileLink)
                setStatus(`Uploaded 1 file: ${file.name}`)
            } else {
                setStatus(`ERROR - There was a problem uploading your file`)
            }
        })
    }

    useEffect(() => {
        signIn()
    }, [])

    return (
        <Wrapper>
            {label && <Label>{label}</Label>}
            <ControlWrap>
                <input
                    type="file"
                    name={id}
                    size="40"
                    id={id}
                    accept={fileTypes}
                    aria-invalid="false"
                    onChange={handleFileInput}
                />
            </ControlWrap>
            <IconWrapper for={id}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                </svg>
                <span>{children}</span>
            </IconWrapper>
            {status && <p>{status}</p>}
        </Wrapper>
    )
}

FileInput.defaultProps = {
    label: null,
    types: [
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
        '.pdf',
        '.doc',
        '.docx',
        '.ppt',
        '.pptx',
        '.odt',
        '.avi',
        '.ogg',
        '.m4a',
        '.mov',
        '.mp3',
        '.mp4',
        '.mpg',
        '.wav',
        '.wmv',
    ],
    required: false,
}

export default FileInput
