import React, { useState, useCallback, useEffect, useMemo } from "react";
import { motion, useAnimation } from "framer-motion";
import { wrap } from "popmotion";
import styled from 'styled-components';
import useDimensions from 'react-use-dimensions';
import { Container, Row, Column, colors, fonts, mediaQueries, grid } from '../styles';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const S = {
    CarouselWrapper: styled.div`
        overflow: hidden;
        position: relative;
    `,
    Carousel: styled(motion.div)`
        display: flex;
        flex-flow: row nowrap;
    `,
    Arrow: styled.div`
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: white;
        padding: 10px;
        background: black;
        z-index: 50;
        line-height: 1;
        opacity: 0.5;
        transition: opacity .2s ease-in;
        cursor: pointer;
        &:hover{
            opacity: 1;
        }
        ${props => props.left && `
            left: 20px;
            &:after{
                content: '\\2190'
            }
        `}	
        ${props => props.right && `
            right: 20px;
            &:after{
                content: '\\2192'
            }
        `}
    `,
    ImgWrapper: styled.div`
        position: relative;
        flex: 1 0 auto;

        .gatsby-image-wrapper{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

    `
};

function getItemWidth(arrayLength) {
    return 1 / arrayLength * 100;
}

const Carousel = ( { nodes }, ...rest ) => {

    const [[currentPage, direction], setCurrentPage] = useState([0, 0]);
    const [trackRef, dimensions] = useDimensions();
    const paginate = useCallback(
        function(newDirection){
            const newPage = wrap(0, nodes.length,currentPage + newDirection)
            setCurrentPage([newPage, newDirection]);    
        }
    , [currentPage]);
    const controls = useAnimation();
    const visibleItems = Math.min(Math.ceil(dimensions.width / 300), nodes.length);
    const itemWidth = useMemo(() => getItemWidth(visibleItems), [visibleItems]);

    console.log(itemWidth)
    
    useEffect(() => {
        const scrollToPage = wrap(0, visibleItems, currentPage);
        controls.start({
            x: `${itemWidth * -scrollToPage}%`
        })
      }, [itemWidth,currentPage,visibleItems])

    return (
        <S.CarouselWrapper>
            <S.Arrow left onClick={ () => paginate(1) } />
            <S.Arrow right onClick={ () => paginate(-1) } />
            <S.Carousel
                ref={trackRef}
                animate={controls}
                transition={{ duration: .6, ease: 'easeInOut' }}      
                >
                    { nodes.length && nodes.map((node, index) => (
                        <S.ImgWrapper key={index} style={{ width: `${itemWidth}%`, paddingBottom: `${itemWidth}%` }}>
                            <GatsbyImage image={getImage(node.childImageSharp.gatsbyImageData)} alt='Alt' />
                        </S.ImgWrapper>
                    )) }
            </S.Carousel>
        </S.CarouselWrapper>
    )
  
}

export default Carousel