import React from 'react'
import styled from 'styled-components'
import { Container } from '../styles'

const StyledVideoWrapper = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: url(${(props) => props.poster}) scroll no-repeat center
        center/cover;

    video {
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;
        object-position: center center;
        z-index: 1;
    }
`

const StyledVideoOverlay = styled.div`
    z-index: 2;
    position: absolute;
    left: 0;
    top 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.overlay || 'rgba(0,0,0,0.25)'}
`

const StyledVideoContent = styled.div`
    position: absolute;
    z-index: 3;
    left: 0;
    top 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: ${(props) => props.color || 'black'};

    ${Container} {
        flex: 1;
    }

`

const VideoBanner = ({ children, overlay, color, src, poster }) => {
    return (
        <StyledVideoWrapper poster={poster}>
            <video
                src={src}
                autoPlay
                loop
                muted
                playsInline
                style={{ width: '100vw', height: '100vh' }}
            />
            <StyledVideoOverlay overlay={overlay} />
            <StyledVideoContent color={color}>
                <Container>{children}</Container>
            </StyledVideoContent>
        </StyledVideoWrapper>
    )
}

export default VideoBanner
