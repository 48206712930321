import styled from 'styled-components'

export const breakpoints = {
    sm: 768,
    md: 960,
    lg: 1280,
}

export const gutter = '10px'

export const grid = {
    maxWidth: '1140',
    columns: 100,
}

export const mediaQueries = {
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
}

const getColumnWidth = (number) => (parseInt(number) / grid.columns) * 100 + '%'

function makeColumn(breakpoint, columnCount) {
    const columnWidth = getColumnWidth(columnCount)

    return `
		${mediaQueries[breakpoint]} {
			width: ${columnWidth}
		}
	`
}

export const Container = styled.div`
    max-width: ${(props) => (props.fluid ? '100%' : `${grid.maxWidth}px`)};
    padding-right: ${gutter};
    padding-left: ${gutter};
    margin-left: auto;
    margin-right: auto;
`

export const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
    margin-right: -${gutter};
    margin-left: -${gutter};
    justify-content: ${(props) => (props.alignX ? props.alignX : 'flex-start')};
    align-items: ${(props) => (props.alignY ? props.alignY : 'flex-start')};
    ${(props) =>
        props.reverse &&
        `
      
        ${mediaQueries.sm} {
            flex-direction: row-reverse;
        }

    `}
`

export const Column = styled.div`
    padding-right: ${gutter};
    padding-left: ${gutter};
    min-height: 1px;
    position: relative;
    width: ${(props) => (props.xs ? getColumnWidth(props.xs) : `100%`)};

    ${(props) =>
        Object.keys(props)
            .filter((p) => Object.keys(breakpoints).includes(p))
            // Make column with breakpoint & column count params
            .map((p) => makeColumn(p, props[p]))}
`

export const colors = {
    main: '#013657', // Navy blue
    dark: '#01263D', // Dark blue
    compliment: '#A32223', // Red
    lightGrey: '#E6EBEF',
    darkGrey: '#BFCDD5',
}

// Src: https://css-tricks.com/converting-color-spaces-in-javascript/
export const hexAToRGBA = (h) => {
    let r = 0,
        g = 0,
        b = 0,
        a = 1

    if (h.length == 5) {
        r = '0x' + h[1] + h[1]
        g = '0x' + h[2] + h[2]
        b = '0x' + h[3] + h[3]
        a = '0x' + h[4] + h[4]
    } else if (h.length == 9) {
        r = '0x' + h[1] + h[2]
        g = '0x' + h[3] + h[4]
        b = '0x' + h[5] + h[6]
        a = '0x' + h[7] + h[8]
    }
    a = +(a / 255).toFixed(3)

    return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + a + ')'
}

export const fonts = {
    headings: `'Norwester', sans`,
    body: `'Nunito Sans', sans`,
    special: `'Kollektif', sans`,
}

export const sizes = {
    min: 0,
    tiny: 25,
    small: 50,
    medium: 100,
    large: 200,
}

export const themes = {
    light: {
        primary: `#ffffff`,
        inverse: `#000000`,
    },
    dark: {
        primary: `#000000`,
        inverse: `#ffffff`,
    },
}

export const clamp = (val, min, max) => Math.max(Math.min(val, min), max)
