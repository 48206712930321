import React from "react"
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { Container } from '../styles';
import FacebookIcon from '../images/facebook.inline.svg';
import InstagramIcon from '../images/instagram.inline.svg';

const StyledFooter = styled.footer`

    color: white;
    background-color: black;
    text-align: center;
    padding: 30px 0px 10px;

`;

const StyledFooterNav = styled.nav`

    margin-bottom: 10px;

    a{
        display: inline-block;
        margin: 0px 5px 10px;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        font-size: 14px;
    }

`;

const d = new Date();

const Footer = () => {
    const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          socials {
            instagram
            facebook
          }
          menus {
            header {
                link
                name
            }
            }
        }
      }	  		
    }
  `);

  const siteMetadata = data.site.siteMetadata;

    return (
        <StyledFooter>
            <Container>
                <p>
                    <a href={ siteMetadata.socials.facebook } target="_blank" style={{marginRight: 10, marginLeft: 10}}><FacebookIcon /></a>
                    <a href={ siteMetadata.socials.instagram } target="_blank" style={{marginRight: 10, marginLeft: 10}}><InstagramIcon /></a>
                </p>
                <StyledFooterNav role="navigation">
                    {
                        siteMetadata.menus.header.map( (item, index) => <a href={ item.link } key={ index }>{ item.name }</a> )
                    }
                </StyledFooterNav>
                <p style={{ fontSize: 12 }}>&copy; {d.getFullYear()} Solar Energy Partners | Powered By <a style={{ textDecoration: 'underline' }} href='https://mysocialhustle.com' target='_blank'>Social Hustle</a></p>
            </Container>
        </StyledFooter>
    )
}

export default Footer