import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'

const StyledHamburgerWrap = styled.button`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    -webkit-appearance: none;
    background-color: transparent;
    z-index: 1000;
`

const StyledHamburgerBar = styled.div`
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: ${(props) => props.theme.primary};

    &:before,
    &:after {
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: currentColor;
        transition: top 0.3s ease,
            transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        content: '';
    }

    &:before {
        top: 9px;
    }

    &:after {
        top: 19px;
    }

    ${(props) =>
        props.isOpen &&
        `

		transform: rotate(45deg);

		&:before{
			transform: translateY(5px);
		}
		&:after{
			transform: translateY(-5px) rotate(-90deg);
		}
    `}
`

const Hamburger = ({ theme, callback }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        callback(open)
    }, [open])

    return (
        <ThemeProvider theme={theme}>
            <StyledHamburgerWrap
                onClick={() => setOpen(!open)}
                className="hamburger"
            >
                <StyledHamburgerBar isOpen={open} />
            </StyledHamburgerWrap>
        </ThemeProvider>
    )
}

Hamburger.defaultProps = {
    callback: () => {},
}

export default Hamburger
