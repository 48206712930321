import React from "react"
import styled from 'styled-components';
import { colors, fonts, mediaQueries } from "../styles";
import { motion } from 'framer-motion';

const StyledBackgroundText = styled(motion.div)`
    font-size: 4em;
    height: 1em;
    color: ${colors.lightGrey};
    font-family: ${fonts.special};
    position: absolute;
    display: block;
    top: -1em;
    left: 0;
    text-transform: uppercase;
    font-weight: bold;
    //letter-spacing: 0.075em;
    perspective: 1000;
    backface-visibility: hidden;

		${mediaQueries.md} {
			font-size: 7.5rem;
		}
`;

const StyledBackgroundTextCharacter = styled(motion.span)`
    display: inline-block;
    width: ${ props => props.isSpace ? '.25em' : 'auto' };
`;

const variants = {
  hidden: {
    opacity: 1
  },
  visible: {
    opacity: 1,
    transition: { 
      staggerChildren: 0.1
    }
  } 
}

const characters = {
  hidden: {
    rotateY: '-90deg',
    opacity: 0.5
  },
  visible: {
    rotateY: '0deg',
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
}

const BackgroundText = ({ content, style }) => {

  return (
    <StyledBackgroundText variants={variants} initial='hidden' whileInView='visible' viewport={{ once: true }} className='background-text'>
      <div style={ style }>
        { content.split('').map((char, index) => (
          <StyledBackgroundTextCharacter key={index} variants={characters} isSpace={char === ' '}>
            { char }
          </StyledBackgroundTextCharacter>
        )) }
      </div>
    </StyledBackgroundText>
  )

}

export default BackgroundText