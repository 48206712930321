import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Container, Row, Column, colors, mediaQueries } from '../styles'
import BlogEntry from './BlogEntry'
import ChevronLeft from '../images/chevron-left.inline.svg'
import ChevronRight from '../images/chevron-right.inline.svg'
import { wrap } from 'popmotion'
import { motion } from 'framer-motion'

const Carousel = styled(Row)`
    flex-wrap: nowrap;
`

const CarouselColumn = styled(motion.div)`
    min-width: 100%;
    display: flex;

    ${mediaQueries.sm} {
        min-width: 50%;
    }
    ${mediaQueries.md} {
        min-width: 33.33333%;
    }
`

const NavWrap = styled.nav`
    position: relative;
    margin-bottom: 50px;
`

const Arrow = styled.button`
    color: white;
    padding: 10px;
    background-color: transparent;
    line-height: 1;
    transition: all 0.2s ease-in;
    cursor: pointer;
    height: 100%;
    -webkit-appearance: none;
    display: inline-block;
    border: 2px solid white;

    &:hover {
        background-color: white;
        color: ${colors.dark};
    }
`

const Nav = ({ paginate }) => {
    return (
        <NavWrap>
            <Arrow onClick={() => paginate(1)} style={{ marginRight: 5 }}>
                <ChevronLeft />
            </Arrow>
            <Arrow onClick={() => paginate(-1)}>
                <ChevronRight />
            </Arrow>
        </NavWrap>
    )
}

const BlogCarousel = ({ nodes }) => {
    const [page, setPage] = useState(0)
    const length = nodes.length
    const translatedValue = page * -100

    const paginate = useCallback(
        (e) => {
            const newPage = wrap(0, length - 2, e + page)
            setPage(newPage)
        },
        [page, length]
    )

    return (
        <>
            <Container>
                <Nav paginate={(e) => paginate(e)} />
            </Container>
            <Container fluid style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Carousel alignX="space-between" alignY="stretch">
                    {nodes.map(({ node }, index) => (
                        <CarouselColumn
                            animate={{ x: `${translatedValue}%` }}
                            transition={{
                                duration: 0.45,
                                ease: 'linear',
                            }}
                            key={index}
                        >
                            <BlogEntry node={node} />
                        </CarouselColumn>
                    ))}
                </Carousel>
            </Container>
        </>
    )
}

export default BlogCarousel
