import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../components/Layout'
import VideoBanner from '../components/VideoBanner'
import { Container, Row, Column, colors, fonts, mediaQueries } from '../styles'
import Section from '../components/Section'
import ScrollSpy from '../components/ScrollSpy'
import SplitSection from '../components/SplitSection'
import Counter from '../components/Counter'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import BackgroundText from '../components/BackgroundText'
import Arrow from '../images/arrowright.inline.svg'
import MailIcon from '../images/mail.inline.svg'
import PhoneIcon from '../images/phone.inline.svg'
import ContactForm from '../components/ContactForm'
import Button from '../components/Button'
import BlogCarousel from '../components/BlogCarousel'
import loadable from '@loadable/component'

const Globe = loadable(() => import('../components/Globe'))

const Blog = () => {
    const data = useStaticQuery(graphql`
        {
            allNewsJson(sort: { fields: date, order: DESC }, limit: 10) {
                edges {
                    node {
                        image {
                            childImageSharp {
                                gatsbyImageData(aspectRatio: 1.25)
                            }
                        }
                        date(formatString: "MM.DD.YY")
                        link
                        title
                    }
                }
            }
        }
    `)

    const blog = data.allNewsJson.edges

    return (
        <Section theme="dark" style={{ paddingBottom: 50 }}>
            <Container style={{ position: 'relative', marginTop: 100 }}>
                <BackgroundText
                    content="Newsroom"
                    style={{ opacity: 0.05, top: -130 }}
                />
                <h2 style={{ marginBottom: 20 }}>In the News</h2>
            </Container>
            <BlogCarousel nodes={blog} />
        </Section>
    )
}

const StyledContactSection = styled.section`
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;

    &:before {
        width: 50%;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        background-color: ${colors.darkGrey};
    }

    ${mediaQueries.sm} {
        &:before {
            content: '';
        }
    }
`

const StyledContactInfo = styled.ul`
    font-family: ${fonts.special};
    letter-spacing: 0.15625em;
    font-size: calc(1rem - 2px);
    text-transform: uppercase;
`

const Contact = () => {
    return (
        <StyledContactSection>
            <Container style={{ position: 'relative', marginTop: 100 }}>
                <BackgroundText content="Contact" style={{ top: -130 }} />
                <Row alignX="space-between">
                    <Column md={40}>
                        <h2 style={{ marginBottom: 20 }}>Get In Touch</h2>
                        <p>Don’t be shy - say hello!</p>
                        <StyledContactInfo className="noIcon">
                            <li>
                                <a href="tel:2093262578">
                                    <PhoneIcon /> (209) 326-2578
                                </a>
                            </li>
                            <li>
                                <a href="mailto:office@solarenergy.partners">
                                    <MailIcon /> office@solarenergy.partners
                                </a>
                            </li>
                        </StyledContactInfo>
                    </Column>
                    <Column md={45}>
                        <ContactForm />
                    </Column>
                </Row>
            </Container>
        </StyledContactSection>
    )
}

// markup
const IndexPage = ({ children }) => {
    return (
        <Layout>
            <main>
                <MDXProvider
                    components={{
                        VideoBanner,
                        Row,
                        Column,
                        Container,
                        Section,
                        ScrollSpy,
                        SplitSection,
                        Counter,
                        Blog,
                        Contact,
                        Button,
                        Globe,
                    }}
                >
                    {children}
                </MDXProvider>
            </main>
        </Layout>
    )
}

export default IndexPage
