import React, { useState } from 'react'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../components/Layout'
import { Container, Row, Column, colors, fonts } from '../styles'
import Section from '../components/Section'
import ScrollSpy from '../components/ScrollSpy'
import SplitSection from '../components/SplitSection'
import { Hero, HeroImage, HeroContent } from '../components/Hero'
import Button from '../components/Button'
import ContactForm from '../components/ContactForm'
import BackgroundText from '../components/BackgroundText'
import ContactStrip from '../components/ContactStrip'
import loadable from '@loadable/component'
import Lottie1 from '../assets/lottie/lottie1.json'
import Lottie2 from '../assets/lottie/lottie2.json'
import Lottie3 from '../assets/lottie/lottie3.json'
import { useForm, useController } from 'react-hook-form'
import Input from '../components/Forms/Input'
import FileInput from '../components/Forms/File'
import Loader from '../components/UI/Loader'
import { ErrorMessage, SuccessMessage } from '../components/Forms/FormMessage'

const LottieFile = loadable(() => import('../components/LottieFile'))

const LottieFile1 = () => {
    return <LottieFile src={Lottie1} />
}
const LottieFile2 = () => {
    return <LottieFile src={Lottie2} />
}
const LottieFile3 = () => {
    return <LottieFile src={Lottie3} />
}

async function submitForm(data) {
    const endpoint = `https://hooks.zapier.com/hooks/catch/7199122/brb1mse/`
    const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({ data: data }),
    })
    return response
}

const CareersForm = () => {
    const { register, handleSubmit, reset, control } = useForm()
    const [result, setResult] = useState(false)

    const { field } = useController({
        control,
        name: 'resume',
    })

    const uploadFile = (e) => {
        console.log(e)
        field.onChange(e)
    }

    function onSubmit(e) {
        setResult('loading')
        submitForm(e).then((response) => {
            if (typeof response.ok !== 'undefined' && response.ok === true) {
                setResult('success')
                reset()
            } else {
                setResult('error')
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Column sm={50}>
                    <Input
                        id="firstname"
                        label="First Name"
                        register={register}
                        required={true}
                    />
                </Column>
                <Column sm={50}>
                    <Input
                        id="lastname"
                        label="Last Name"
                        register={register}
                        required={true}
                    />
                </Column>
            </Row>
            <Row>
                <Column sm={50}>
                    <Input
                        type="email"
                        id="email"
                        label="Your Email"
                        register={register}
                        required={true}
                    />
                </Column>
                <Column sm={50}>
                    <Input
                        id="phone"
                        label="Your Phone"
                        register={register}
                        required={true}
                    />
                </Column>
            </Row>
            <Input
                id="experience"
                label="Years & Months of Solar Experience"
                register={register}
                required={true}
            />{' '}
            <FileInput
                label="Upload Your CV or Resume:"
                id="resume"
                setFileLink={(e) => uploadFile(e)}
            >
                Choose a File
            </FileInput>
            <Button as="button" type="submit">
                Submit
            </Button>
            {result === 'submitting' && <Loader />}
            {result === 'success' && (
                <SuccessMessage>
                    Your message has been sent and we will be in touch shortly.
                </SuccessMessage>
            )}
            {result === 'error' && (
                <ErrorMessage>
                    There has been an error and we are unable to process your
                    form. Please try again later.
                </ErrorMessage>
            )}
        </form>
    )
}

// markup
const CareerPage = ({ children, pageContext: { frontmatter } }) => {
    return (
        <Layout {...frontmatter}>
            <main>
                <MDXProvider
                    components={{
                        Row,
                        Column,
                        Container,
                        Section,
                        ScrollSpy,
                        SplitSection,
                        Hero,
                        HeroImage,
                        HeroContent,
                        Button,
                        ContactForm,
                        BackgroundText,
                        ContactStrip,
                        LottieFile1,
                        LottieFile2,
                        LottieFile3,
                        CareersForm,
                    }}
                >
                    {children}
                </MDXProvider>
            </main>
        </Layout>
    )
}

export default CareerPage
