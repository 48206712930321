import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import styled from "styled-components";
import { wrap } from "popmotion";
import ChevronLeft from '../images/chevron-left.inline.svg';
import ChevronRight from '../images/chevron-right.inline.svg';

const Nav = styled.div`
    position: absolute;
    right: 2.5px;
    bottom: 5px;
    z-index: 1;
    color: #ffffff;
`;

const NavButton = styled.div`
    border: 1px solid #ffffff;
    height: 1.5em;
    width: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 2.5px;
    cursor: pointer;
`;

const StyledSlider = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 50%;
`;

const Slide = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:after{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
  }

`;

const SliderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;

const StyledIndicators = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledIndicatorContainer = styled.div`
  padding: 20px;
  cursor: pointer;
`;

const StyledIndicator= styled.div`
  width: 10px;
  height: 10px;
  background: ${props => props.dotColor ? props.dotColor : 'rgba(255,255,255,.5)'};
  border-radius: 50%;
  position: relative;
`;

const StyledIndicatorHighlight = styled(motion.div)`
  top: -2px;
  left: -2px;
  background: ${props => props.activeDotColor ? props.activeDotColor : '#ffffff'};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
`;

// Variants in framer-motion define visual states
// that a rendered motion component can be in at
// any given time.
 
const xOffset = 100;
const variants = {
  enter: (direction) => ({
    x: direction > 0 ? xOffset : -xOffset,
    //opacity: 0
  }),
  active: {
    x: 0,
    //opacity: 1,
    //transition: { delay: 0.1 }
  },
  exit: (direction) => ({
    x: direction > 0 ? -xOffset : xOffset,
    //opacity: 0
  })
};

const Pagination = ({ currentPage, setPage, images, options }) => {
  // Wrap all the pagination Indicators
  // with AnimateSharedPresence
  // so we can detect when Indicators
  // with a layoutId are removed/added
    if (options.dots){
        return (
            <AnimateSharedLayout>
            <StyledIndicators>
                {images.map((img, index) => (
                <Indicator
                    key={index}
                    onClick={() => setPage(index)}
                    isSelected={index === currentPage}
                    options={options}
                />
                ))}
            </StyledIndicators>
            </AnimateSharedLayout>
        );
    }  

    return null;

};

const Indicator = ({ isSelected, onClick, options }) => {
  return (
    <StyledIndicatorContainer onClick={onClick}>
      <StyledIndicator dotColor={options.dotColor}>
        {isSelected && (
          // By setting layoutId, when this component
          // is removed and a new one is added elsewhere,
          // the new component will animate out from the old one.
          <StyledIndicatorHighlight
                      layoutId="highlight"
                      activeDotColor={options.activeDotColor} />
        )}
      </StyledIndicator>
    </StyledIndicatorContainer>
  );
};

const PageSlider = ({ currentPage, setPage, direction, images }) => {
  /* Add and remove pages from the array to checkout
     how the gestures and pagination animations are
     fully data and layout-driven. */
  const hasPaginated = useRef(false);

  const activeSlide = images[currentPage];
 
  function detectPaginationGesture(e, { offset }) {
    if (hasPaginated.current) return;
    let newPage = currentPage;
    const threshold = xOffset / 2;
 
    if (offset.x < -threshold) {
      // If user is dragging left, go forward a page
      newPage = currentPage + 1;
    } else if (offset.x > threshold) {
      // Else if the user is dragging right,
      // go backwards a page
      newPage = currentPage - 1;
    }
 
    if (newPage !== currentPage) {
      hasPaginated.current = true;
      // Wrap the page index to within the
      // permitted page range
      newPage = wrap(0, images.length, newPage);
      setPage(newPage, offset.x < 0 ? 1 : -1);
    }
  }
 
  return (
    <SliderContainer>
      <AnimatePresence
        // This will be used for components to resolve
        // exit variants. It's necessary as removed
        // components won't rerender with
        // the latest state (as they've been removed)
        custom={direction}
        >
        <Slide
          key={currentPage}
          data-page={currentPage}
          variants={variants}
          initial="enter"
          animate="active"
          exit="exit"
          drag="x"
          onDrag={detectPaginationGesture}
          onDragStart={() => (hasPaginated.current = false)}
          onDragEnd={() => (hasPaginated.current = true)}
          // Snap the component back to the center
          // if it hasn't paginated
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          // This will be used for components to resolve all
          // other variants, in this case initial and animate.
          custom={direction}
          transition={{ duration: .5, ease: 'easeInOut' }}
        >
          { activeSlide }
        </Slide>
      </AnimatePresence>
    </SliderContainer>
  );
};

const Arrows = ({ arrows, currentPage, setPage, length }) => {
    if (arrows){
        return (
            <Nav>
                <NavButton onClick={ () => { setPage(wrap(0, length, currentPage - 1)) } }>
                    <ChevronLeft />
                </NavButton>
                <NavButton onClick={ () => { setPage(wrap(0, length, currentPage + 1)) } }>
                    <ChevronRight />
                </NavButton>
            </Nav>
        )
    }

    return null;
}


const Slider = ( {children, dots, dotColor, activeDotColor, autoplay, arrows} ) => {

  /* We keep track of the pagination direction as well as
   * current page, this way we can dynamically generate different
   * animations depending on the direction of travel */
  const [[currentPage, direction], setCurrentPage] = useState([0, 0]);
 
  function setPage(newPage, newDirection) {
    if (!newDirection) newDirection = newPage - currentPage;
    setCurrentPage([newPage, newDirection]);
  }

  useEffect(() => {
      if (autoplay){
        const timer = setTimeout(() => {
            if (currentPage + 1 < children.length) {
              setPage(currentPage+1, 1)
            } else {
              setPage(0, -1)
            }
          }, 3000);
          return () => clearTimeout(timer);      
      }
  }, [currentPage]);

  return (
    <StyledSlider>
        <Arrows 
        arrows={arrows} 
        setPage={setPage} 
        currentPage={currentPage}
        length={children.length}
        />
      <PageSlider
        currentPage={currentPage}
        direction={direction}
        setPage={setPage}
        images={children}
      />
        <Pagination currentPage={currentPage}
        setPage={setPage}
        images={children}
        options={{ dotColor: dotColor, activeDotColor: activeDotColor, dots: dots }} />
    </StyledSlider>
  );

};

Slider.defaultProps = {
    dots: true,
    dotColor: 'rgba(255,255,255,0.5)',
    activeDotColor: '#ffffff',
    autoplay: true,
    arrows: true
}


export default Slider