import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { fonts, mediaQueries, themes } from '../../styles'
import TransitionLink from 'gatsby-plugin-transition-link'
import Button from '../Button'
//import SubMenu from "./SubMenu";

const StyledHeaderNavBar = styled.nav`
    align-items: center;
    font-family: ${fonts.headings};
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${(props) => props.theme.primary};
    line-height: 1;
    font-size: 18px;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.inverse};
    padding: 100px 20px 20px;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.7, 0, 0.3, 1);

    &.open {
        transform: translateX(0%);
    }

    ${mediaQueries.md} {
        display: flex;
        background-color: transparent;
        font-size: 14px;
        padding: 0;
        transform: none;
        height: unset;
        width: unset;
        position: static;
    }

    ${mediaQueries.lg} {
        font-size: 16px;
    }
`

const StyledHeaderNavLink = styled.div`
    margin: 20px 10px 20px 0px;
    position: relative;

    ${mediaQueries.md} {
        display: inline-block;
        margin: 0px 30px 0px 0px;
    }

    &:hover .submenu {
        transform: scaleY(1);
    }
`

const NavBar = ({ menu, theme, open }) => {
    const providedTheme = themes[theme]
    const { primary, inverse } = providedTheme

    return (
        <ThemeProvider theme={providedTheme}>
            <StyledHeaderNavBar className={open ? 'open' : ''}>
                {menu.map((item, index) => (
                    <StyledHeaderNavLink key={index}>
                        <TransitionLink
                            to={item.link}
                            exit={{ length: 1.2, zIndex: 2 }}
                            entry={{ length: 1.2, zIndex: 1 }}
                        >
                            {item.name}
                        </TransitionLink>
                        {/**<SubMenu items={item.submenu} theme={theme} />**/}
                    </StyledHeaderNavLink>
                ))}
                <Button
                    hoverBackground={primary}
                    hoverColor={inverse}
                    style={{ letterSpacing: '.05em' }}
                    to="/explore-solar/"
                    exit={{ length: 1.2, zIndex: 2 }}
                    entry={{ length: 1.2, zIndex: 1 }}
                >
                    Get Started
                </Button>
            </StyledHeaderNavBar>
        </ThemeProvider>
    )
}

export default NavBar
