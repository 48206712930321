import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../../styles'

const StyledSelect = styled.select`
    border: 1px solid ${colors.dark};
    padding: 20px;
    -webkit-appearance: menulist;
    display: block;
    margin-bottom: 20px;
    color: ${colors.dark};
    width: 100%;
    font-family: ${fonts.body};
    font-size: 1rem;
`

const Select = ({ required, name, options, register }) => {
    return (
        <StyledSelect
            aria-invalid="false"
            aria-required={required}
            required={required}
            name={name}
            {...register(name, {
                required: required,
                onChange: (e) => console.log(e.target.value),
            })}
        >
            {options.length &&
                options.map((item, index) => (
                    <option value={item} key={index}>
                        {item}
                    </option>
                ))}
        </StyledSelect>
    )
}

Select.defaultProps = {
    required: false,
    name: 'state',
}

export default Select
