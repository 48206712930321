import * as React from 'react'
import Footer from './Footer'
import Header from './Header'
import GlobalStyle from './GlobalStyle'
import PageTransition from './PageTransition'
import Seo from './Seo'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Modal from './Modal'
import CookieBanner from './CookieBanner'

const Layout = (props) => {
    const {
        children,
        title,
        description,
        image,
        showHeader,
        showFooter,
        headerTheme,
    } = props

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
            scriptProps={{
                async: true, // optional, default to false,
                defer: true, // optional, default to false
                appendTo: 'body', // optional, default to "head", can be "head" or "body",
            }}
        >
            <Seo title={title} description={description} image={image} />
            <CookieBanner />
            <GlobalStyle />
            <Modal />
            <PageTransition>
                {showHeader && <Header theme={headerTheme} />}
                {children}
                {showFooter && <Footer />}
            </PageTransition>
        </GoogleReCaptchaProvider>
    )
}

Layout.defaultProps = {
    title: null,
    description: null,
    image: null,
    headerTheme: 'light',
    showHeader: true,
    showFooter: true,
}

export default Layout
