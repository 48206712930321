import React from "react"
import styled, { ThemeProvider } from 'styled-components';
import { colors, sizes } from '../styles';

const themes = {
    light: {
        background: 'white',
        color: '#6e6e6e',
        headings: 'black'
    },
    dark: {
        background: colors.dark,
        color: 'white',
        headings: 'white'
    }
}

const StyledSection = styled.section`
    position: relative;
    background-color: ${props => props.theme.background};
    color: ${props => props.theme.color};
    padding-top: ${props => props.size}px;
    padding-bottom: ${props => props.size}px;

    h1,h2,h3,h4,h5,h6{
        color: ${props => props.theme.headings};
    }

`;

const Section = ( props ) => {

    const { theme, size, style, children, ...rest } = props;

    return (
        <ThemeProvider theme={ themes[theme] }>

            <StyledSection size={sizes[size]} style={style} {...rest}>
                { children }
            </StyledSection>

        </ThemeProvider>        
    )

}

Section.defaultProps = {
    theme: 'light',
    size: 'medium'
}

export default Section