import React, {
    useState,
    useEffect,
    useRef,
    useLayoutEffect,
    useCallback,
    useMemo,
} from 'react'
import styled from 'styled-components'
import { colors, clamp } from '../../styles'
import { motion, useTransform, useMotionValue } from 'framer-motion'

const handleDimensions = 30

const S = {
    SliderContainer: styled.div`
        position: relative;
        padding: 20px 0;
    `,
    Slider: styled.div`
        height: 12px;
        border-radius: 10px;
        display: block;
        box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
        background-color: ${colors.lightGrey};
        touch-action: none;
        margin-top: 50px;
        position: relative;
    `,
    Fill: styled(motion.div)`
        height: 100%;
        background-color: ${colors.compliment};
        border-radius: 10px;
        top: 0;
        box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
        position: absolute;
        left: 0;
        width: 50%;
    `,
    Handle: styled(motion.div)`
        width: ${handleDimensions}px;
        height: ${handleDimensions}px;
        border-radius: ${handleDimensions}px;
        top: 50%;
        left: 50%;
        margin-left: ${handleDimensions / -2}px;
        margin-top: ${handleDimensions / -2}px;
        background: #fff;
        border: 1px solid #ccc;
        cursor: pointer;
        display: inline-block;
        position: absolute;
        box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);

        &:after {
            content: ' ';
            position: absolute;
            width: 16px;
            height: 16px;
            top: 6px;
            left: 6px;
            border-radius: 50%;
            background-color: ${colors.darkGrey};
            box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%),
                inset 0 -1px 3px rgb(0 0 0 / 40%);
        }
    `,
    Tooltip: styled(motion.div)`
        background-color: ${colors.dark};
        color: white;
        font-size: 14px;
        padding: 5px;
        border-radius: 3px;
        position: absolute;
        top: -50px;
        left: 50%;
        width: 50px;
        text-align: center;
        margin-left: -25px;

        &:after {
            position: absolute;
            display: block;
            content: '';
            bottom: -10px;
            left: 50%;
            width: 0;
            height: 0;
            margin-left: -5px;
            overflow: hidden;
            border: 5px solid transparent;
            border-top-color: ${colors.dark};
        }
    `,
}

const Range = ({ min, max, callback }) => {
    const mid = Math.ceil((max - min) / 2)
    const constraintsRef = useRef(null)
    const handleRef = useRef(null)
    const [value, setValue] = useState(mid)
    const [dimensions, setDimensions] = useState([0, 0])
    const x = useMotionValue(0)
    const fillWidth = useTransform(x, dimensions, ['0%', '100%'])

    useLayoutEffect(() => {
        const onResize = () => {
            const track = constraintsRef.current
            const handle = handleRef.current
            const width = {
                track: track.getBoundingClientRect().width,
                handle: handle.getBoundingClientRect().width,
            }
            setDimensions([
                (width.track - width.handle) / -2,
                (width.track - width.handle) / 2,
            ])
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [constraintsRef, handleRef])

    useEffect(() => {
        function updateValue() {
            const percentage = parseFloat(fillWidth.get()) / 100
            const range = max - min
            const val = percentage * range
            const rounded = Math.round(val / 10) * 10
            setValue(rounded)
        }
        callback(value)

        const unsubscribeX = x.onChange(updateValue)

        return () => {
            unsubscribeX()
        }
    }, [value])

    return (
        <S.SliderContainer>
            <S.Slider ref={constraintsRef}>
                <S.Tooltip style={{ x }}>${value}</S.Tooltip>
                <S.Fill style={{ width: fillWidth }} />
                <S.Handle
                    drag="x"
                    dragConstraints={constraintsRef}
                    dragElastic={false}
                    style={{ x }}
                    ref={handleRef}
                />
            </S.Slider>
        </S.SliderContainer>
    )
}

Range.defaultProps = {
    min: 1,
    max: 500,
}

export default Range
