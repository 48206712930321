import React from "react";
import styled from 'styled-components';

const StyledHero = styled.div`
    position: relative;
    height: 100vh;
`;

const StyledHeroImage = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    picture, img{
        object-position: center center;
        object-fit: cover;
    }

    .gatsby-image-wrapper,
    .gatsby-resp-image-wrapper,
    p{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;   
        margin-bottom: 0;
    }

    .gatsby-image-wrapper,
    .gatsby-resp-image-wrapper{  
        opacity: ${props => props.overlayOpacity};     
    }
`;

const StyledHeroContent = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;

    & > div {
        flex-grow: 1;
    }
`;

export const HeroImage = ({ overlayColor, overlayOpacity, children }) => {
    return(
        <StyledHeroImage overlayOpacity={overlayOpacity} style={{ backgroundColor: overlayColor }}>
            { children }
        </StyledHeroImage>
    )
}

HeroImage.defaultProps = {
    overlayColor: '#000000',
    overlayOpacity: 0.75
}

export const HeroContent = ({ color, children }) => {

    return (

            <StyledHeroContent style={{ color: color }}>
                <div>{ children }</div>
            </StyledHeroContent>

    )

}

HeroContent.defaultProps = {
    color: '#ffffff'
}

export const Hero = ({ children }) => {

    return (

        <StyledHero>
                { children }
        </StyledHero>

    )

}