import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { colors, themes, mediaQueries } from '../styles'
import { useStaticQuery, graphql } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'
import { motion, useAnimation } from 'framer-motion'
import NavBar from './Header/NavBar'
import LogoSvg from '../images/logo.inline.svg'
import Hamburger from './Header/Hamburger'

// Scroll direction: https://www.youtube.com/watch?v=cTYmwtY2EhQ

const variants = {
    initial: {
        y: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        transition: {
            duration: 0.2,
            type: 'tween',
            ease: [0.25, 0.1, 0.25, 1],
        },
    },
    hidden: {
        y: '-200%',
        backgroundColor: colors.dark,
        transition: {
            duration: 0.1,
            ease: [0.25, 0.1, 0.25, 1],
            type: 'tween',
            backgroundColor: { delay: 0.5 },
        },
    },
    shown: {
        y: 0,
        backgroundColor: colors.dark,
        transition: {
            duration: 0.1,
            ease: [0.25, 0.1, 0.25, 1],
            type: 'tween',
            backgroundColor: { delay: 0.5 },
        },
    },
}

const StyledLogo = styled(LogoSvg)`
    fill: ${(props) => themes[props.theme].primary};
    height: 25px;
    width: auto;
    transition: all 0.35s ease-in-out;

    &:hover {
        fill: ${colors.compliment};
    }

    ${mediaQueries.lg} {
        height: 40px;
    }
`

const StyledHeader = styled(motion.header)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 20px 30px;
    transition: transform 0.8s;
    transform: translateZ(0);
    will-change: transform;

    ${mediaQueries.md} {
        .hamburger {
            display: none;
        }
    }
`

const StyledHeaderInner = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
`

const Header = ({ theme }) => {
    const [lastYPos, setLastYPos] = useState(0)
    const [themeLogo, setThemeLogo] = useState(theme)
    const [navOpen, setNavOpen] = useState(false)

    const controls = useAnimation()

    const handleScroll = useCallback(() => {
        const yPos = window.scrollY
        const isScrollingUp = yPos < lastYPos

        if (yPos == 0) {
            controls.start('initial')
            setThemeLogo(theme)
        } else if (isScrollingUp) {
            controls.start('shown')
            setThemeLogo('light')
        } else {
            controls.start('hidden')
            setThemeLogo('light')
        }

        setLastYPos(window.scrollY)
    }, [lastYPos])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false)

        return () => {
            window.removeEventListener('scroll', handleScroll, false)
        }
    }, [handleScroll])

    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    menus {
                        header {
                            link
                            name
                            submenu {
                                link
                                name
                            }
                        }
                    }
                }
            }
        }
    `)

    const menu = data.site.siteMetadata.menus.header

    return (
        <StyledHeader variants={variants} animate={controls}>
            <StyledHeaderInner>
                <TransitionLink
                    to="/"
                    exit={{ length: 1.2, zIndex: 2 }}
                    entry={{ length: 1.2, zIndex: 1 }}
                    style={{
                        position: 'relative',
                        zIndex: 1,
                        display: 'block',
                        lineHeight: 0,
                    }}
                >
                    <StyledLogo theme={themeLogo} />
                </TransitionLink>
                <Hamburger
                    theme={themes[themeLogo]}
                    callback={(e) => setNavOpen(e)}
                />
                <NavBar menu={menu} theme={themeLogo} open={navOpen} />
            </StyledHeaderInner>
        </StyledHeader>
    )
}

Header.defaultProps = {
    theme: 'light',
}

export default Header
