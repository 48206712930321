import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { fonts, mediaQueries } from "../styles";
import { animate, motion } from "framer-motion";

const StyledCounterWrapper = styled.div`
    
    margin-bottom: 50px;

    ${props => props.divider &&
      `&:after{
        content: '';
        width: 65px;
        height: 2px;
        display: block;
        background-color: currentColor;
        margin-top: 10px;
    }`
  }

`;

const StyledCounterDigit = styled(motion.div)`
    font-family: ${fonts.special};
    font-weight: 600;
    word-wrap: break-word;
    font-size: 2rem;
    line-height: 1;
    position: relative;

    ${mediaQueries.md} {
      font-size: 4rem;
    }


`;

// https://stackoverflow.com/questions/60205544/in-framer-motion-how-to-animate-pure-data-like-a-number-from-0-to-100

function CounterDigit({ from, to, inView }) {
    const nodeRef = useRef();
  
    useEffect(() => {
      const node = nodeRef.current;
  
      if (inView){
        const controls = animate(from, to, {
          duration: 1.5,
          onUpdate(value) {
            node.textContent = value.toFixed(0);
          }
        });
    
        return () => controls.stop();
      }
    }, [from, to, inView]);
  
    return <span ref={nodeRef} />;
  }

const Counter = ( { startDigit, digit, divider, children, textBefore, textAfter, ...rest } ) => {
  
    const [inView, setInView] = useState(false);

    return (
        <StyledCounterWrapper divider={divider} {...rest}>
            <StyledCounterDigit onViewportEnter={ () => setInView(true) }>
                {textBefore}
                <CounterDigit from={startDigit} to={digit} inView={inView} />
                {textAfter}
            </StyledCounterDigit>
            <div>{ children }</div>
        </StyledCounterWrapper>
    );

}

Counter.defaultProps = {
  divider: true,
  textBefore: '',
  textAfter: '',
  startDigit: 0,
  digit: 100

}

export default Counter