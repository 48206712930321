import React from 'react'
import { colors } from '../../styles'

const Loader = (props) => {
    const { color } = props

    return (
        <div style={{ marginTop: 10 }}>
            <svg
                height="50px"
                width="60px"
                x="0px"
                y="0px"
                viewBox="0 0 60 20"
                enable-background="new 0 0 0 0"
                {...props}
            >
                <circle fill={color} stroke="none" cx="6" cy="10" r="6">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 15 ; 0 -15; 0 15"
                        repeatCount="indefinite"
                        begin="0.1"
                    ></animateTransform>
                </circle>
                <circle fill={color} stroke="none" cx="30" cy="10" r="6">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 10 ; 0 -10; 0 10"
                        repeatCount="indefinite"
                        begin="0.2"
                    ></animateTransform>
                </circle>
                <circle fill={color} stroke="none" cx="54" cy="10" r="6">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 5 ; 0 -5; 0 5"
                        repeatCount="indefinite"
                        begin="0.3"
                    ></animateTransform>
                </circle>
            </svg>
        </div>
    )
}

Loader.defaultProps = {
    color: colors.darkGrey,
}

export default Loader
