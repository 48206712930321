import styled, { keyframes } from 'styled-components'
import { colors } from '../../styles'

const slideDown = keyframes`
    0% {
        opacity: 0, scale(0)
    }
    100% {
        opacity: 1, scale(1)
    }
`

const ConfirmationBox = styled.div`
    padding: 10px 20px;
    color: white;
    margin: 20px 0;
    animation: ${slideDown} 0.5s ease;
`

export const ErrorMessage = styled(ConfirmationBox)`
    background-color: ${colors.compliment};
    color: white;
`

export const SuccessMessage = styled(ConfirmationBox)`
    background-color: #225705;
    color: white;
`
