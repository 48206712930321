import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../../styles'

const Wrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
    font-weight: 400;
    overflow: hidden;
`

const Label = styled.label`
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    padding: 20px;
    line-height: 1;

    span {
        transition: all 0.25s;
        transform-origin: left top;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(20px, 20px);
    }
`

const StyledInput = styled.input`
    display: block;
    border-radius: 0;
    -webkit-appearance: none;
    width: 100%;
    color: ${colors.dark};
    border: 1px solid black;
    font-family: ${fonts.body};
    letter-spacing: 1px;
    outline: none;
    line-height: 1;
    padding: 25px 20px 15px;

    &.filled {
        & + ${Label} {
            span {
                transform: scale(0.7) translate(30px, 10px);
            }
        }
    }
`

const setFilled = (e) => {
    e.target.classList.add('filled')
}

const removeFilled = (e) => {
    //console.log(e.target.value)
    if (typeof e.target.value === 'undefined' || e.target.value === '') {
        e.target.classList.remove('filled')
    }
}

const Input = (props) => {
    const { id, label, register, required, type, ...rest } = props

    useEffect(() => {
        const el = document.getElementById(id)
        if (el) {
            el.addEventListener('focus', setFilled, false)
            el.addEventListener('blur', removeFilled, false)
        }

        return () => {
            el.removeEventListener('focus', setFilled, false)
            el.removeEventListener('blur', removeFilled, false)
        }
    }, [])

    return (
        <Wrapper>
            <StyledInput
                {...rest}
                id={id}
                type={type}
                required={required}
                {...register(id, { required: required })}
            />
            {label && (
                <Label htmlFor={id}>
                    <span>{label}</span>
                </Label>
            )}
        </Wrapper>
    )
}

Input.defaultProps = {
    type: 'text',
    label: '',
    defaultValue: '',
    required: false,
}

export default Input
