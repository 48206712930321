export const isBrowser = typeof window !== 'undefined'

export function getCookie(cname) {
    if (typeof document !== 'undefined') {
        var name = cname + '='
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
    }
    return ''
}

export function setCookie(cname, cvalue, exdays) {
    if (typeof document !== 'undefined') return false
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    d.setHours(0)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const hasStorage = () => {
    try {
        return (
            isBrowser &&
            'sessionStorage' in window &&
            window['sessionStorage'] !== null
        )
    } catch (e) {
        return false
    }
}

export function setStorage(key, val) {
    if (hasStorage()) {
        window.sessionStorage.setItem(key, val)
    } else {
        return false
    }
}

export function getStorage(key) {
    return hasStorage() ? window.sessionStorage.getItem(key) : false
}

// https://seifi.org/reactjs/build-a-contact-form-in-gatsby-part-2-react-hook-form.html
export async function submitForm(
    data,
    endpoint = `https://fmqweytbn3.execute-api.us-east-1.amazonaws.com/Production/`
) {
    const response = await fetch(endpoint, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
    })
    return response
}
