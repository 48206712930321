import React from "react"
import { MDXProvider } from "@mdx-js/react";
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout";
import { Container, Row, Column, colors, fonts, mediaQueries } from '../styles';
import Section from '../components/Section';
import { Hero, HeroImage, HeroContent } from "../components/Hero";
import ScrollSpy from "../components/ScrollSpy";
import SplitSection from "../components/SplitSection";
import Counter from '../components/Counter';
import ContactForm from '../components/ContactForm';
import Button from "../components/Button";
import Carousel from '../components/Carousel';
import loadable from "@loadable/component"
import BackgroundText from "../components/BackgroundText";

const Globe = loadable(() => import('../components/Globe'))

const LifestyleCarousel = () => {
    const data = useStaticQuery(graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "lifestyle"}}) {
            nodes {
              id
              childImageSharp {
                gatsbyImageData(width: 300, height: 300)
              }
            }
        }
      }
    `)
    return (
        <Section style={{ paddingBottom: 0 }}>
            <Container style={{ position: 'relative', marginTop: 100 }}>
                <h2 style={{ position: 'relative', zIndex: 1 }}>See It For Yourself</h2>
                <BackgroundText content='Lifestyle' style={{ top: -130 }} />
            </Container>
            <Carousel nodes={data.allFile.nodes} />
        </Section>
    )
}

// markup
const LifestylePage = ({ children, pageContext: {frontmatter} }) => {
  return (
    <Layout {...frontmatter}>
      <main>
        <MDXProvider components={{
              Row,
              Column,
              Container,
              Section,
              ScrollSpy,
              SplitSection,
              Counter,
              Button,
              Globe,
              Hero, HeroImage, HeroContent,
              LifestyleCarousel,
              ContactForm,
              BackgroundText
        }}>{ children }</MDXProvider>
      </main>
    </Layout>
  )
}

export default LifestylePage
