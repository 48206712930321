import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from 'styled-components';
import { colors, fonts } from '../../styles';
import ChevronDown from '../../images/chevron-down.inline.svg';

const AccordionHeader = styled(motion.header)`
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
    border-bottom: 1px solid ${colors.lightGrey};
    font-size: 1.125rem;
    font-family: ${ fonts.headings };
    text-transform: uppercase;
`;

const AccordionBody = styled(motion.div)`
    overflow: hidden;
`;

const AccordionBodyContent = styled(motion.div)`
    padding: 20px 0;
    transform-origin: top center;
`;

const Accordion = ({ children, title }) => {
  
    const [expanded, setExpanded] = useState(false);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <AccordionHeader
        initial={false}
        animate={{ color: expanded ? colors.compliment : "#000000" }}
        onClick={() => setExpanded(!expanded)}
      >
          { title }
          <motion.div animate={{ rotate: expanded ? '-180deg' : "0deg" }} transition={{ duration: 0.6, ease: 'linear' }}><ChevronDown /></motion.div>
      </AccordionHeader>
      <AnimatePresence initial={false}>
        {expanded && (
          <AccordionBody
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <AccordionBodyContent
                variants={{ collapsed: { scale: 0.95 }, open: { scale: 1 } }}
                transition={{ duration: 0.8 }}
            >
                { children }
            </AccordionBodyContent>
          </AccordionBody>
        )}
      </AnimatePresence>
    </>
  );
};

export default Accordion;