import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useLocation } from '@gatsbyjs/reach-router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { colors } from '../styles'

const CookieBanner = () => {
    const location = useLocation()

    return (
        <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            cookieName={process.env.GATSBY_GTM_COOKIE_NAME}
            style={{ backgroundColor: colors.dark, zIndex: 3147482997 }}
            buttonStyle={{
                backgroundColor: colors.compliment,
                color: '#ffffff',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '.8rem',
            }}
            declineButtonStyle={{
                backgroundColor: '#ffffff',
                color: colors.dark,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '.8rem',
                marginRight: 0,
            }}
            onAccept={() => {
                initializeAndTrack(location)
                document.getElementById('podium-website-widget').style.display =
                    'block'
            }}
        >
            We use cookies on this website to enhance your user experience. For
            more info,{' '}
            <a href="/privacy-policy/" style={{ textDecoration: 'underline' }}>
                click here.
            </a>
        </CookieConsent>
    )
}

export default CookieBanner
