import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Row, Column } from '../styles'
import Button from './Button'
import Input from './Forms/Input'
import { SuccessMessage, ErrorMessage } from './Forms/FormMessage'
import { submitForm } from '../utils'
import Loader from './UI/Loader'

const ContactForm = () => {
    const [result, setResult] = useState(false)
    const { register, handleSubmit, reset } = useForm()

    function onSubmit(fields) {
        setResult('submitting')
        submitForm({
            form: 'Contact',
            fields: fields,
        }).then((response) => {
            if (typeof response.ok !== 'undefined' && response.ok === true) {
                setResult('success')
                reset()
            } else {
                setResult('error')
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Column sm={50}>
                    <Input
                        id="name"
                        label="Your Name"
                        register={register}
                        required={true}
                    />
                </Column>
                <Column sm={50}>
                    <Input
                        type="email"
                        id="email"
                        label="Your Email"
                        register={register}
                        required={true}
                    />
                </Column>
            </Row>
            <Input
                id="phone"
                label="Your Phone"
                register={register}
                required={true}
            />
            <Input
                id="message"
                rows="5"
                as="textarea"
                label="Your Message"
                register={register}
                required={true}
            />
            <Button as="button" type="submit">
                Submit
            </Button>
            {result === 'submitting' && <Loader />}
            {result === 'success' && (
                <SuccessMessage>
                    Your message has been sent and we will be in touch shortly.
                </SuccessMessage>
            )}
            {result === 'error' && (
                <ErrorMessage>
                    There has been an error and we are unable to process your
                    form. Please try again later.
                </ErrorMessage>
            )}
        </form>
    )
}

export default ContactForm
