import React from "react"
import { MDXProvider } from "@mdx-js/react";
import Layout from "../components/Layout";
import styled from 'styled-components';
import { Container, Row, Column } from '../styles';
import Section from '../components/Section';
import ScrollSpy from "../components/ScrollSpy";
import SplitSection from "../components/SplitSection";
import { Hero, HeroImage, HeroContent } from "../components/Hero";
import Button from "../components/Button";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "../components/Slider";
import { colors } from "../styles";

const PortfolioInfo = styled.div`

    border-top: 2px solid ${colors.main};
    padding-top: 20px;
    margin: 20px 0;

`;

const ProjectsSection = () => {

    const data = useStaticQuery(graphql`
    {
      allMdx(filter: {fields: {collection: {eq: "projects"}}}) {
        edges {
          node {
            frontmatter {
              gallery {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              title
            }
          }
        }
      }
    }
  `);

  const projects = data.allMdx.edges;

  return (

    <Section>
        <Container>
            <Row>
                { projects.map( ({node}, index) => (
                    <Column sm={33} key={index}>
                        <Slider dots={false} autoplay={false}>
                        { node.frontmatter.gallery.map( (img, index) => (
                            <GatsbyImage key={index} alt='img' image={getImage(img)} />
                        ) ) }
                        </Slider>
                        <PortfolioInfo>
                            <h3>{ node.frontmatter.title }</h3>
                        </PortfolioInfo>
                    </Column>
                ) ) }
            </Row>
        </Container>
    </Section>

  )

}

// markup
const ProjectsPage = ({ children, pageContext: {frontmatter} }) => (
    <Layout {...frontmatter} headerTheme='dark'>
      <main>
        <MDXProvider components={{
              Row,
              Column,
              Container,
              Section,
              ScrollSpy,
              SplitSection,
              Hero,
              HeroImage, 
              HeroContent,
              Button,
              ProjectsSection
        }}>{ children }</MDXProvider>
      </main>
    </Layout>
  )

  export default ProjectsPage