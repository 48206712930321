import React from 'react'
import { motion } from 'framer-motion'
import { TransitionState } from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import { colors } from '../styles'

const Wrapper = styled.div`
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: white;
`

const Box = styled(motion.div)`
    background-color: ${colors.dark};
    position: fixed;
    top: 0;
    width: 50%;
    z-index: 100;
    height: 100%;
    transform-origin: 0 0;
    ${(props) =>
        props.position === `left` &&
        `
        left: 0;
    `}
    ${(props) =>
        props.position === `right` &&
        `
        right: 0;
    `}
`

const PageTransition = ({ transitionStatus, children }) => {
    const variants = {
        entering: {
            scaleY: 1,
        },
        entered: {
            scaleY: 0,
        },
        exiting: {
            scaleY: 1,
        },
    }

    const transition = { duration: 1, ease: [0.65, 0, 0.35, 1] }

    return (
        <TransitionState>
            {({ transitionStatus }) => (
                <>
                    <Wrapper>{children}</Wrapper>
                    <Box
                        position="left"
                        variants={variants}
                        animate={transitionStatus}
                        transition={transition}
                    />
                    <Box
                        position="right"
                        variants={variants}
                        animate={transitionStatus}
                        transition={{ delay: 0.25, ...transition }}
                    />
                </>
            )}
        </TransitionState>
    )
}

export default PageTransition
