import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { grid, colors, gutter, mediaQueries } from '../styles'
import Button from './Button'
import { StaticImage } from 'gatsby-plugin-image'
import CloseIcon from '../images/close.inline.svg'
import { getStorage, setStorage } from '../utils'

const ModalContainer = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5vh ${gutter};
    /**overflow: hidden scroll;**/
`

const ModalOverlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
`

const ModalInner = styled.div`
    flex: 1;
    max-height: 100%;
    max-width: ${grid.maxWidth}px;
    display: flex;
    color: white;
    position: relative;
    flex-direction: column;

    ${mediaQueries.sm} {
        flex-direction: row;
    }
`

const ModalThird = styled.div`
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: max(20px, 5%);
    text-align: center;
    position: relative;
    overflow: hidden;

    ${mediaQueries.md} {
        width: 33.33333%;
        padding: max(10%, 15vh) max(5%, 20px);
    }

    .modalHalf__backgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;

        &:after {
            content: '';
            background: black;
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0.45;
        }
    }
`

const Close = styled.button`
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: white;
    color: black;
    padding: 10px;
    transition: all 0.5s;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    font-size: 0.8em;
    z-index: 1;

    &:hover {
        background-color: ${colors.compliment};
        color: white;
    }
`

const Modal = () => {
    const [display, setDisplay] = useState(false)

    useEffect(() => {
        let timer
        const popupShown = getStorage('popupShown')
        if (!popupShown) {
            timer = setTimeout(() => {
                setDisplay(true)
                setStorage('popupShown', true)
            }, 5000)
        }
        return () => clearTimeout(timer)
    }, [])

    return (
        <AnimatePresence>
            {display && (
                <>
                    <ModalOverlay
                        key="overlay"
                        initial={{ opacity: 0, y: '100%' }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { duration: 0.25 },
                        }}
                        exit={{ opacity: 0, y: '100%' }}
                    />
                    <ModalContainer
                        key="modal"
                        role="dialog"
                        aria-modal="true"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { delay: 0.25 },
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.5,
                            transition: { duration: 0.2 },
                        }}
                    >
                        <ModalInner>
                            <Close onClick={() => setDisplay(false)}>
                                <CloseIcon />
                            </Close>
                            <ModalThird>
                                <h3 style={{ marginBottom: 30 }}>
                                    Solar Careers
                                </h3>
                                <p>
                                    Join the fastest growing career in the
                                    nation!
                                </p>
                                <Button
                                    to="/careers/"
                                    exit={{ length: 1.2, zIndex: 2 }}
                                    entry={{ length: 1.2, zIndex: 1 }}
                                    style={{ fontSize: 12 }}
                                >
                                    Get Started
                                </Button>
                                <StaticImage
                                    src="../images/careers.jpg"
                                    alt="Solar Careers"
                                    className="modalHalf__backgroundImage"
                                />
                            </ModalThird>
                            <ModalThird>
                                <h3 style={{ marginBottom: 30 }}>Home Solar</h3>
                                <p>Choose sustainability and save money.</p>
                                <Button
                                    to="/explore-solar/"
                                    exit={{ length: 1.2, zIndex: 2 }}
                                    entry={{ length: 1.2, zIndex: 1 }}
                                    style={{ fontSize: 12 }}
                                >
                                    Go Solar
                                </Button>
                                <StaticImage
                                    src="../images/environment-hero.jpg"
                                    alt="Solar"
                                    className="modalHalf__backgroundImage"
                                />
                            </ModalThird>
                            <ModalThird>
                                <h3 style={{ marginBottom: 30 }}>
                                    Dealer Program
                                </h3>
                                <p>
                                    Launch &amp; scale your own solar franchise.
                                </p>
                                <Button
                                    to="/dealer-program/"
                                    exit={{ length: 1.2, zIndex: 2 }}
                                    entry={{ length: 1.2, zIndex: 1 }}
                                    style={{ fontSize: 12 }}
                                >
                                    Learn More
                                </Button>
                                <StaticImage
                                    src="../images/energy-independence.jpg"
                                    alt="Solar"
                                    className="modalHalf__backgroundImage"
                                />
                            </ModalThird>
                        </ModalInner>
                    </ModalContainer>
                </>
            )}
        </AnimatePresence>
    )
}

export default Modal
