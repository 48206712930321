import React, { useState, useRef, useEffect } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { motion, AnimatePresence } from 'framer-motion'
import Layout from '../components/Layout'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
    Container,
    Row,
    Column,
    colors,
    gutter,
    mediaQueries,
    themes,
} from '../styles'
import Section from '../components/Section'
import Button from '../components/Button'
import Range from '../components/Forms/Range'
import Select from '../components/Forms/Select'
import StateSelect from '../components/Forms/StateSelect'
import Input from '../components/Forms/Input'
import LogoSvg from '../images/logo.inline.svg'
import ResidentialIcon from '../images/residential.inline.svg'
import CommercialIcon from '../images/commercial.inline.svg'
import InsitutionalIcon from '../images/institutional.inline.svg'
import CompShingle from '../images/shingle.inline.svg'
import ConcreteRoof from '../images/concrete-tile.inline.svg'
import SpanishTile from '../images/spanish-tile.inline.svg'
import MetalRoof from '../images/metal.inline.svg'
import WoodRoof from '../images/wood.inline.svg'
import OtherRoof from '../images/other-roof.inline.svg'
import { ErrorMessage, SuccessMessage } from '../components/Forms/FormMessage'
import { submitForm } from '../utils'
import TransitionLink from 'gatsby-plugin-transition-link'
import Loader from '../components/UI/Loader'
import Hamburger from '../components/Header/Hamburger'

// https://seifi.org/aws/build-a-contact-form-in-gatsby-part-1-aws-lambda-simple-email-service-and-api-gateway.html

const S = {
    Aside: styled.aside`
        background-color: ${colors.dark};
        padding: 5vh 10px;

        ${mediaQueries.sm} {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 25%;
        }

        ${mediaQueries.md} {
            padding: 5vh;
        }
    `,
    Main: styled.main`
        padding: 5vh 30px;
        min-height: 100vh;
        ${mediaQueries.sm} {
            padding: 10vh 30px 5vh calc(25% + 30px);
        }
    `,
    Logo: styled(LogoSvg)`
        fill: white;
        height: auto;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        display: block;

        ${mediaQueries.sm} {
            margin-bottom: 50px;
        }
    `,
    StepWrapper: styled.div`
        position: relative;
        line-height: 1;
    `,
    StepRoot: styled.div`
        display: flex;
        align-items: center;
        color: white;
        flex-flow: row wrap;
    `,
    StepIcon: styled.div`
        display: flex;
        flex-shrink: 0;
        padding-right: 10px;
        color: ${(props) => (props.active ? colors.compliment : `white`)};

        svg {
            width: 1.25em;
            height: 1.25em;
            fill: currentColor;

            text {
                font-size: 0.75em;
            }
        }
    `,
    StepLabel: styled.label`
        display: block;
        font-size: 1.25rem;
    `,
    StepData: styled.ul`
        width: 100%;
        padding-left: 1.25em;
        margin-left: 10px;
        margin-top: 20px;

        li {
            font-size: 0.85em;
            margin-bottom: 10px;
            transform-origin: center top;
        }
    `,
    StepsWrapper: styled.div`
        max-height: 0px;
        overflow: hidden;
        transition: all 0.8s;

        &.open {
            padding-top: 50px;
            max-height: unset;
        }

        ${mediaQueries.sm} {
            &,
            &.open {
                max-height: unset;
                padding-top: 0px;
            }
        }
    `,
    Divider: styled.div`
        padding-left: 0.625em;
        width: 100%;
        position: absolute;
        left: 0;
        top: 1.25em;
        bottom: 0px;

        svg {
            width: 1.25em;
            height: 100%;
        }
    `,
    SelectBox: styled.div`
        position: relative;

        &:hover > label {
            color: ${colors.compliment};
        }

        input {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            pointer-events: none;

            &:checked + label {
                color: ${colors.compliment};
            }
        }

        label {
            padding: 20px ${gutter};
            color: black;
            border: 2px solid currentColor;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            svg {
                width: 100%;
                max-width: 100px;
                height: 100px;
                fill: currentColor;
            }

            span {
                display: block;
                margin-top: 1em;
            }
        }
    `,
}

const HamburgerColumn = styled(Column)`
    text-align: right;
    ${mediaQueries.sm} {
        display: none;
    }
`

const steps = [
    {
        title: 'Property Type',
    },
    {
        title: 'Address',
    },
    {
        title: 'Electric Bill',
    },
    {
        title: 'Contact',
    },
]

const SelectBox = ({ name, val, label, children, register }) => {
    return (
        <S.SelectBox>
            <input
                type="radio"
                id={`${name}-${val}`}
                value={val}
                {...register(name, { required: true })}
            />
            <label htmlFor={`${name}-${val}`}>
                {children}
                <span>{label}</span>
            </label>
        </S.SelectBox>
    )
}

const listVariants = {
    initial: {
        scaleY: 0,
    },
    animate: {
        scaleY: 1,
    },
    exit: {
        scaleY: 0,
    },
}

const Step = ({ index, title, active, last, data }) => {
    const dataMap = data !== null ? Object.values(data) : []

    return (
        <S.StepWrapper>
            <S.StepRoot>
                <S.StepIcon active={active}>
                    <svg
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                    >
                        <circle cx="12" cy="12" r="12"></circle>
                        <text
                            x="12"
                            y="16"
                            textAnchor="middle"
                            fill={active ? 'white' : colors.dark}
                        >
                            {index + 1}
                        </text>
                    </svg>
                </S.StepIcon>
                <S.StepLabel>{title}</S.StepLabel>
                <S.StepData>
                    <AnimatePresence exitBeforeEnter initial={false}>
                        {dataMap.length > 0 &&
                            dataMap.map((item, index) => (
                                <motion.li
                                    key={index}
                                    variants={listVariants}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    transition={{
                                        duration: 0.75,
                                        ease: [0.85, 0, 0.15, 1],
                                    }}
                                >
                                    {item}
                                </motion.li>
                            ))}
                    </AnimatePresence>
                </S.StepData>
            </S.StepRoot>
            {!last && (
                <S.Divider>
                    <svg
                        viewBox="0 0 24 100"
                        aria-hidden="true"
                        preserveAspectRatio="none"
                    >
                        <line x1="0" y1="20" x2="0" y2="80" stroke="white" />
                    </svg>
                </S.Divider>
            )}
        </S.StepWrapper>
    )
}

const PropertySection = ({ callBack }) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm()

    console.log(errors)

    const onSubmit = (data) => callBack(data)

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Section size="tiny">
                <h3>select your property type</h3>
                {errors.property_type && (
                    <ErrorMessage>Please select a property type.</ErrorMessage>
                )}
                <Row alignX="space-between">
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Residential"
                            name="property_type"
                            val="Residential"
                            register={register}
                        >
                            <ResidentialIcon />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Commercial"
                            name="property_type"
                            val="Commercial"
                            register={register}
                        >
                            <CommercialIcon />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Insitutional"
                            name="property_type"
                            val="Insitutional"
                            register={register}
                        >
                            <InsitutionalIcon />
                        </SelectBox>
                    </Column>
                </Row>
            </Section>
            <Section size="tiny">
                <h3>Do you</h3>
                <Select
                    required={true}
                    name="ownership_type"
                    options={['Rent', 'Own w/ mortgage', 'Own outright']}
                    register={register}
                />
            </Section>
            <Section size="tiny">
                <h3>select your roof type</h3>
                {errors.roof_type && (
                    <ErrorMessage>Please select a roof type.</ErrorMessage>
                )}
                <Row alignX="space-between" alignY="stretch">
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Comp Shingle"
                            name="roof_type"
                            val="Comp Shingle"
                            register={register}
                        >
                            <CompShingle />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Concrete Tile"
                            name="roof_type"
                            val="Concrete Tile"
                            register={register}
                        >
                            <ConcreteRoof />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Spanish Tile"
                            name="roof_type"
                            val="Spanish Tile"
                            register={register}
                        >
                            <SpanishTile />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Wood Shake"
                            name="roof_type"
                            val="Wood Shake"
                            register={register}
                        >
                            <WoodRoof />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Metal"
                            name="roof_type"
                            val="Metal"
                            register={register}
                        >
                            <MetalRoof />
                        </SelectBox>
                    </Column>
                    <Column sm={33} style={{ marginBottom: 20 }}>
                        <SelectBox
                            label="Other"
                            name="roof_type"
                            val="Other"
                            register={register}
                        >
                            <OtherRoof />
                        </SelectBox>
                    </Column>
                </Row>
            </Section>
            <Section size="tiny">
                <h3>Roof Condition</h3>
                <Select
                    required={true}
                    name="roof_condition"
                    options={[
                        '25+ Years Old',
                        '15-25 Years Old',
                        '5-15 Years Old',
                        'Recently Replaced',
                        'Not Sure',
                    ]}
                    register={register}
                />
            </Section>
            <Button as="button" type="submit">
                NEXT
            </Button>
        </form>
    )
}

const AddressSection = ({ callBack }) => {
    const { handleSubmit, register } = useForm()

    const onSubmit = (data) => callBack(data)

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Section size="tiny">
                <h3>Enter Your Address</h3>
                <Input
                    id="address"
                    label="Enter Your Address"
                    register={register}
                    required={true}
                />
            </Section>
            <Section size="tiny">
                <h3>Select the State You Live In</h3>
                <StateSelect name="state" required={true} register={register} />
            </Section>
            <Section size="tiny">
                <h3>Enter Your Zip Code</h3>
                <Input
                    id="zip_code"
                    label="Enter Your Zip Code"
                    register={register}
                    required={true}
                />
            </Section>
            <Button as="button" type="submit">
                NEXT
            </Button>
        </form>
    )
}

const ElectricBillSection = ({ callBack }) => {
    const { handleSubmit, register, setValue } = useForm()

    const onSubmit = (data) => callBack(data)

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Section size="tiny">
                <h3>your average electricity bill</h3>
                <Range
                    callback={(e) => {
                        setValue('electric_bill', `$${e}`)
                    }}
                />
            </Section>
            <Section size="tiny">
                <h3>Enter Your Utility Provider</h3>
                <Input
                    id="utility_provider"
                    label="Enter Your Utility Provider"
                    register={register}
                    required={true}
                />
            </Section>
            <Button as="button" type="submit">
                NEXT
            </Button>
        </form>
    )
}

const ContactSection = ({ callBack }) => {
    const { handleSubmit, register } = useForm()

    const onSubmit = (data) => callBack(data)

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Section size="tiny">
                <h3>Enter Your Full Name</h3>
                <Row>
                    <Column sm={50}>
                        <Input
                            id="first_name"
                            label="First Name"
                            register={register}
                            required={true}
                        />
                    </Column>
                    <Column sm={50}>
                        <Input
                            id="last_name"
                            label="Last Name"
                            register={register}
                            required={true}
                        />
                    </Column>
                </Row>
            </Section>
            <Section size="tiny">
                <h3>Enter Your Phone Number</h3>
                <Input
                    id="phone"
                    type="tel"
                    label="Phone Number"
                    register={register}
                    required={true}
                />
            </Section>
            <Section size="tiny">
                <h3>Enter Your Email Address</h3>
                <Input
                    id="email"
                    type="email"
                    label="Email Address"
                    register={register}
                    required={true}
                />
            </Section>
            <Button as="button" type="submit">
                SUBMIT
            </Button>
        </form>
    )
}

const variants = {
    initial: {
        opacity: 0,
        y: 100,
    },
    animate: {
        opacity: 1,
        y: 0,
    },
    exit: {
        opacity: 0,
        y: -100,
    },
}

const components = [
    PropertySection,
    AddressSection,
    ElectricBillSection,
    ContactSection,
]

const Nav = ({ step, data }) => {
    const ref = useRef(null)

    const openNav = (e) => {
        if (ref.current) {
            if (e === true) {
                ref.current.classList.add('open')
            } else {
                ref.current.classList.remove('open')
            }
        }
    }

    return (
        <S.Aside>
            <Row alignX="center">
                <Column xs={50} sm={90}>
                    <TransitionLink
                        to="/"
                        exit={{ length: 1.2, zIndex: 2 }}
                        entry={{ length: 1.2, zIndex: 1 }}
                    >
                        {' '}
                        <S.Logo />
                    </TransitionLink>
                </Column>
                <HamburgerColumn xs={50}>
                    <Hamburger
                        theme={themes.light}
                        callback={(e) => openNav(e)}
                    />
                </HamburgerColumn>
            </Row>
            <S.StepsWrapper ref={ref}>
                {steps.length &&
                    steps.map((item, index) => (
                        <Step
                            title={item.title}
                            index={index}
                            key={index}
                            active={index === step}
                            last={index >= steps.length - 1}
                            data={
                                typeof data[index] !== 'undefined'
                                    ? data[index]
                                    : null
                            }
                        />
                    ))}
            </S.StepsWrapper>
        </S.Aside>
    )
}

const SavingsForm = ({ formName, children }) => {
    const [step, setStep] = useState(0)
    const [data, setData] = useState({})
    const [result, setResult] = useState(false)

    useEffect(() => {
        if (
            Object.keys(data).length === components.length &&
            step === components.length - 1
        ) {
            let fields = {}
            Object.entries(data).forEach(([index, values]) =>
                Object.assign(fields, values)
            )
            submitForm({
                form: formName,
                fields: fields,
            }).then((response) => {
                if (
                    typeof response.ok !== 'undefined' &&
                    response.ok === true
                ) {
                    setResult('success')
                    setStep(components.length)
                } else {
                    setResult('error')
                }
            })
            //console.log(data)
        }
    }, [data, step, formName])

    const callBack = (vals, page) => {
        const max = components.length - 1
        setData({ ...data, [page]: { ...vals } })
        if (page < max) {
            setStep(step + 1)
        } else {
            setResult('submitting')
        }
    }

    return (
        <>
            <Nav step={step} data={data} />
            <S.Main>
                <Container>
                    {children}
                    <AnimatePresence exitBeforeEnter initial={false}>
                        {components.length &&
                            components.map(
                                (Item, index) =>
                                    step === index && (
                                        <motion.div
                                            key={index}
                                            variants={variants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{
                                                duration: 0.75,
                                                ease: [0.85, 0, 0.15, 1],
                                            }}
                                        >
                                            <Item
                                                callBack={(e) => {
                                                    callBack(e, index)
                                                }}
                                            />
                                        </motion.div>
                                    )
                            )}
                    </AnimatePresence>
                    {result === 'submitting' && <Loader />}
                    {result === 'success' && (
                        <SuccessMessage>
                            Your message has been sent and we will be in touch
                            shortly.{' '}
                            <TransitionLink
                                to="/explore-solar/"
                                exit={{ length: 1.2, zIndex: 2 }}
                                entry={{ length: 1.2, zIndex: 1 }}
                            >
                                <u>Click here to go back.</u>
                            </TransitionLink>
                        </SuccessMessage>
                    )}
                    {result === 'error' && (
                        <ErrorMessage>
                            There has been an error and we are unable to process
                            your form. Please try again later.
                        </ErrorMessage>
                    )}
                </Container>
            </S.Main>
        </>
    )
}

// markup
const SavingsPage = ({ children }) => {
    return (
        <Layout showHeader={false} showFooter={false}>
            <MDXProvider
                components={{
                    Row,
                    Column,
                    Container,
                    SavingsForm,
                }}
            >
                {children}
            </MDXProvider>
        </Layout>
    )
}

export default SavingsPage
