import React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'
import Arrow from '../images/arrowright.inline.svg'
import TransitionLink from 'gatsby-plugin-transition-link'

const StyledButton = styled(TransitionLink)`
    display: inline-block;
    letter-spacing: 0.16667em;
    font-weight: lighter;
    text-transform: uppercase;
    transition: all 0.5s ease;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    overflow: hidden;

    svg {
        width: 1.5em;
        vertical-align: middle;
    }
`

const StyledButtonSpanVisible = styled.span`
    padding: 1em 2em;
    transition: transform 0.3s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    display: block;
    vertical-align: middle;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};

    ${StyledButton}:hover & {
        transform: translate3d(100%, 0, 0);
    }
`

const StyledButtonSpanHidden = styled.span`
    padding: 1em 2em;
    transition: transform 0.3s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    background-color: ${(props) => props.hoverBackground};
    color: ${(props) => props.hoverColor};

    ${StyledButton}:hover & {
        transform: translate3d(0, 0, 0);
    }
`

const Button = (props) => {
    const {
        backgroundColor,
        color,
        hoverBackground,
        hoverColor,
        children,
        ...rest
    } = props

    return (
        <StyledButton
            exit={{ length: 1.2, zIndex: 2 }}
            entry={{ length: 1.2, zIndex: 1 }}
            {...rest}
        >
            <StyledButtonSpanVisible
                backgroundColor={backgroundColor}
                color={color}
            >
                {children} <Arrow />
            </StyledButtonSpanVisible>
            <StyledButtonSpanHidden
                hoverColor={hoverColor}
                hoverBackground={hoverBackground}
            >
                {children} <Arrow />
            </StyledButtonSpanHidden>
        </StyledButton>
    )
}

Button.defaultProps = {
    backgroundColor: colors.compliment,
    color: '#fff',
    hoverBackground: '#000',
    hoverColor: '#fff',
}

export default Button
