import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import Section from './Section';
import { colors, mediaQueries, Container, Row, Column } from "../styles";
import { GatsbyImage } from "gatsby-plugin-image";
import BackgroundText from "./BackgroundText";
import { motion } from 'framer-motion';

function handleSplitPosition(position) {

    if (position == 'left') {
        return `
            left: 0;
        `
    } else {
        return `
            right: 0;
        `   
    }

}

function handleTransformOrigin(position) {
    if (position == 'left') {
        return `0 0`
    } else {
        return `100% 0`   
    }
}

const StyledBackground = styled.div`
    position: absolute;
    top: 0;
    background-color: ${props => props.background};
    ${props => handleSplitPosition(props.position)};
    z-index: 0;
    height: 40%;
    width: 100%;

    ${mediaQueries.sm} {
        height: 100%;
        width: 40%;    
    }
    
`;

const StyledRow = styled(Row)`
    ${mediaQueries.sm} {
        flex-direction: ${props => props.position == 'right' ? 'row-reverse' : 'row'}
    }
`;

const StyledBackgroundText = styled.div`

    .background-text{
        left: ${props => props.position == 'right' ? '0%' : '-50%'};
        right: ${props => props.position == 'left' ? '0%' : '-50%'};
        text-align: ${props => props.position == 'right' ? 'left' : 'right'};
    }
`;

const StyledImgWrapper = styled.div`
    position: relative;
    padding-bottom: ${props => props.imgPadding};
`;

const Overlay = styled(motion.div)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
`;

const MotionImg = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .gatsby-image-wrapper{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
`;

const variants = {
    img: {
        initial: position => ({
            x: position == 'left' ? '-100%' : '100%'
        }),
        inView: { 
            x: 0,
            transition: { duration: .75, type: 'tween' }
        }      
    },
    wrapper: {
        initial: position => ({
            x: position == 'left' ? '-100%' : '100%'
        }),
        inView: { 
            x: 0,
            transition: { duration: 1, type: 'tween', delayChildren: .5 }
        }
    }
}

const SplitSection = ( props ) => {

    const data = useStaticQuery(graphql`
        {
        allImageSharp {
            edges {
            node {
                parent {
                    ... on File {
                        name
                    }
                }
                gatsbyImageData(placeholder: BLURRED)
            }
            }
        }
        }
    `);

  const images = data.allImageSharp.edges;
  const img = images.filter(item => item.node.parent.name == props.img)[0];

  const imgPadding = props.backgroundText !== '' ? '125%' : '100%';

    return (
        <Section {...props}>
            <StyledBackground background={props.background} position={props.position} />
            <Container>
                <StyledRow alignX='space-between' alignY='center' position={props.position}>
                    <Column sm={50}>
                        <StyledImgWrapper imgPadding={imgPadding}>
                            <Overlay initial={{ scaleX: 1 }} whileInView={{ scaleX: 0 }} viewport={{ once: true }} transition={{ duration: 1, type: 'tween' }} style={{ backgroundColor: props.imgBackground, transformOrigin: handleTransformOrigin(props.position) }} />
                            <MotionImg>
                                <GatsbyImage image={img.node.gatsbyImageData} alt={props.title} />
                            </MotionImg>
                        </StyledImgWrapper>
                    </Column>
                    <Column sm={40}>
                        <StyledBackgroundText position={props.position}>
                            <BackgroundText content={props.backgroundText} />
                        </StyledBackgroundText>
                        <div style={{ position: 'relative' }}>
                            <h2>{ props.title }</h2>
                            { props.children }
                        </div>
                    </Column>
                </StyledRow>
            </Container>
        </Section>
    )

}

SplitSection.defaultProps = {
    background: colors.darkGrey,
    position: 'right',
    backgroundText: '',
    title: '',
    size: 'medium',
    imgBackground: colors.main
}

export default SplitSection