import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset-advanced'
import NunitoSansRegularWoff from '../fonts/nunitosans-regular-webfont.woff'
import NunitoSansRegularWoff2 from '../fonts/nunitosans-regular-webfont.woff2'
import NunitoSansRegularItalicWoff from '../fonts/nunitosans-italic-webfont.woff'
import NunitoSansRegularItalicWoff2 from '../fonts/nunitosans-italic-webfont.woff2'
import NunitoSansLightWoff from '../fonts/nunitosans-light-webfont.woff'
import NunitoSansLightWoff2 from '../fonts/nunitosans-light-webfont.woff2'
import NunitoSansLightItalicWoff from '../fonts/nunitosans-lightitalic-webfont.woff'
import NunitoSansLightItalicWoff2 from '../fonts/nunitosans-lightitalic-webfont.woff2'
import NunitoSansBoldWoff from '../fonts/nunitosans-bold-webfont.woff'
import NunitoSansBoldWoff2 from '../fonts/nunitosans-bold-webfont.woff2'
import NunitoSansBoldItalicWoff from '../fonts/nunitosans-bolditalic-webfont.woff'
import NunitoSansBoldItalicWoff2 from '../fonts/nunitosans-bolditalic-webfont.woff2'
import KollektifWoff from '../fonts/kollektif-webfont.woff'
import KollektifWoff2 from '../fonts/kollektif-webfont.woff2'
import KollektifItalicWoff from '../fonts/kollektif-italic-webfont.woff'
import KollektifItalicWoff2 from '../fonts/kollektif-italic-webfont.woff2'
import KollektifBoldWoff from '../fonts/kollektif-bold-webfont.woff'
import KollektifBoldWoff2 from '../fonts/kollektif-bold-webfont.woff2'
import KollektifBoldItalicWoff from '../fonts/kollektif-bolditalic-webfont.woff'
import KollektifBoldItalicWoff2 from '../fonts/kollektif-bolditalic-webfont.woff2'
import NorwesterWoff from '../fonts/norwester-webfont.woff'
import NorwesterWoff2 from '../fonts/norwester-webfont.woff2'
import { mediaQueries } from '../styles'

const GlobalStyle = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Norwester';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${NorwesterWoff2}) format('woff2'),
         url(${NorwesterWoff}) format('woff');
  }
  @font-face {
    font-family: 'Kollektif';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${KollektifWoff2}) format('woff2'),
         url(${KollektifWoff}) format('woff');
  }
  @font-face {
    font-family: 'Kollektif';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(${KollektifItalicWoff2}) format('woff2'),
         url(${KollektifItalicWoff}) format('woff');
  }
  @font-face {
    font-family: 'Kollektif';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${KollektifBoldWoff2}) format('woff2'),
         url(${KollektifBoldWoff}) format('woff');
  }
  @font-face {
    font-family: 'Kollektif';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url(${KollektifBoldItalicWoff2}) format('woff2'),
         url(${KollektifBoldItalicWoff}) format('woff');
  }
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${NunitoSansRegularWoff2}) format('woff2'),
         url(${NunitoSansRegularWoff}) format('woff');
  }
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url(${NunitoSansRegularItalicWoff2}) format('woff2'),
         url(${NunitoSansRegularItalicWoff}) format('woff');
  } 
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url(${NunitoSansLightWoff2}) format('woff2'),
         url(${NunitoSansLightWoff}) format('woff');
  }
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url(${NunitoSansLightItalicWoff2}) format('woff2'),
         url(${NunitoSansLightItalicWoff}) format('woff');
  } 
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${NunitoSansBoldWoff2}) format('woff2'),
         url(${NunitoSansBoldWoff}) format('woff');
    }
  @font-face {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url(${NunitoSansBoldItalicWoff2}) format('woff2'),
         url(${NunitoSansBoldItalicWoff}) format('woff');
  }
  html{
      font-size: 100%;
      overflow-x: hidden;
      scroll-behavior: smooth;

      ${mediaQueries.md} {
        font-size: 112.5%;
      }
  
      
  }
  body{
      font-family: 'Nunito Sans', sans;
      line-height: 1.5;
      font-size: 1rem;
  }
  p{
    margin-bottom: 20px;
  }
  ul, ol, li{
    margin-bottom: 10px;
  }
  ul, ol{
    &:after{
      clear: both;
      display: table;
      content: '';
    }
  }
  ol{
    list-style: inside decimal;
  }
  h1,h2,h3,h4,h5,h6{
    margin-bottom: 50px;
  }
  h1,h2,h3,h4,h5,h6{
      font-family: 'Norwester', sans;
      text-transform: uppercase;
      line-height: 1.0625;
    }
  h1{
    font-size: 4.5rem;
  }
  h2{
    font-size: 3rem;
  }
  h3{
    font-size: 2rem;
  }
  h4{
    font-size: 1.125rem;
  }
  h5{
    font-size: 1rem;
  }
  h6{
    font-size: 0.875rem;
  }
  .icon{
    height: 1em;
    width: 1em;
    fill: currentColor;
  }
  main {
    ul:not(.noIcon){
      list-style: outside none none;
    
      li{
        line-height: 2;
        padding-left: 2em;
        position: relative;
    
        &:before{
        background: url('/chevron.svg') scroll no-repeat center center/contain;
        position: absolute;
        left: 0;
        top: .5em;
        height: 1em;
        width: 1em;
        content: '';
        }
    
      }
    }	
  }

  #podium-website-widget{
    display: none;
  }

`

export default GlobalStyle
