import React from 'react'
import { motion } from 'framer-motion'

const ScrollSpy = ({ visible, hidden, children }) => {
    const variants = {
        visible: visible,
        hidden: hidden,
    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <motion.div
                variants={variants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                {children}
            </motion.div>
        </div>
    )
}

ScrollSpy.defaultProps = {
    visible: { x: '0%', transition: { duration: 1, type: 'tween' } },
    hidden: { x: '-100%' },
}

export default ScrollSpy
