import React from 'react'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../components/Layout'
import { Container, Row, Column } from '../styles'
import Section from '../components/Section'
import ScrollSpy from '../components/ScrollSpy'
import SplitSection from '../components/SplitSection'
import Button from '../components/Button'
import Counter from '../components/Counter'
import loadable from '@loadable/component'

const Sun = loadable(() => import('../components/Sun'))
const Map = loadable(() => import('../components/GoogleMap'))

const S = {
    Hero: styled.div`
        min-height: 100vh;
        position: relative;
        background: #000000 url(${(props) => props.image}) scroll no-repeat
            center calc(50% - 22.5vh) / 300px auto;

        .scene-container {
            margin-left: auto;
            margin-right: auto;
        }
    `,
    Text: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        color: #ffffff;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    `,
}

const Hero = () => {
    return (
        <S.Hero image="/lightbulb.png">
            <S.Text>
                <Container>
                    <h1>your solar energy partners.</h1>
                    <Button
                        to="/explore-solar/"
                        exit={{ length: 1.2, zIndex: 2 }}
                        entry={{ length: 1.2, zIndex: 1 }}
                    >
                        Get Started
                    </Button>
                </Container>
            </S.Text>
            <Sun />
        </S.Hero>
    )
}

const shortcodes = {
    Row,
    Column,
    Container,
    Section,
    ScrollSpy,
    SplitSection,
    Hero,
    Button,
    Counter,
    Map,
}

// markup
const AboutPage = ({ children, pageContext: { frontmatter } }) => (
    <Layout {...frontmatter}>
        <main>
            <MDXProvider components={shortcodes}>{children}</MDXProvider>
        </main>
    </Layout>
)

export default AboutPage
