import React from 'react'
import Button from './Button'

export default function SmoothScrollButton({ children, target, ...props }) {
    const onClick = () => {
        console.log(target)
        if (typeof window !== 'undefined') {
            const el = document.getElementById(target)
            if (el) {
                window.scrollTo(0, el.offsetTop)
            }
        }
    }

    return (
        <Button {...props} as="button" onClick={() => onClick()}>
            {children}
        </Button>
    )
}
