import React, { useState } from "react";
import { Row, Column, colors, fonts } from '../styles';
import styled from 'styled-components';
import Button from "./Button";

const StyledInput = styled.input`
    display: block;
    margin-bottom: 20px;
    padding: 20px;
    color: ${colors.dark};
    border: 1px solid black;
    width: 100%;
    font-family: ${fonts.body};
    letter-spacing: 1px;

    &::placeholder{
        opacity: .65;
    }

`;

const StyledBar = styled.div`
    padding: 50px 20px;
    background-color: ${colors.main};
    color: white;
`;

const ContactStrip = (props) => {

    const {children, textAfter, ...rest} = props;

    const initialState = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    const [state, setState] = useState(initialState)

    function handleSubmit(e) {
        console.log('Submitted')
    }

    function handleChange(e) {
        const newValue = e.target.value;
        const name = e.target.name;
    
        setState({
          ...state,
          [name]: newValue
        });
    }

    return (
      <StyledBar {...rest}>
        { children }
        <form onSubmit={ handleSubmit }>
            <StyledInput type='text' value={state.firstName} name='first-name' onChange={handleChange} placeholder='Your First Name' />
            <StyledInput type='text' value={state.lastName} name='last-name' onChange={handleChange} placeholder='Your Last Name' />
            <StyledInput type='text' value={state.phone} name='phone' onChange={handleChange} placeholder='Your Phone' />
            <StyledInput type='email' value={state.email} name='email' onChange={handleChange} placeholder='Your Email' />
            <StyledInput value={state.message} name='subject' onChange={handleChange} rows='5' as='textarea' placeholder='Your Message' />
            <Button as='button' type='submit'>Submit</Button>
        </form>
        <div dangerouslySetInnerHTML={{ __html: textAfter }} />
      </StyledBar>
    )

}

ContactStrip.defaultProps = {
  textAfter: ''
}

export default ContactStrip